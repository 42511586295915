'use client'

import { useEffect } from 'react'
import { getLogger } from '@cerifi/logger'

import ErrorMessage from '../error-message'

export default function Error({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  const logger = getLogger('error')

  useEffect(() => {
    logger.error(error)
  }, [error, logger])

  return <ErrorMessage reset={reset} error={error} />
}
